import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');
html{
  scroll-behavior: smooth;
}
body{
  font-family: 'Quicksand', sans-serif;
  margin: 0;
}
::-webkit-scrollbar { width: 8px; height: 8px }::-webkit-scrollbar-thumb { cursor: pointer; background: #000 }::selection { background-color: #000; color: #fff }-webkit-::selection { background-color: #000; color: #fff }::-moz-selection { background-color: #000; color: #fff }

`;
