import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const Item = styled.p`
  margin: 10px 20px;
`;

const transition = css`
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: #fff;
  ${transition}

  &:hover {
    color: #c5c5c5;
  }
`;

export const InternalLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const CTAButton = styled.div`
  background-color: #fff;
  border-radius: 4px;
  ${transition}

  &:hover {
    background-color: #c5c5c5;
  }
`;
