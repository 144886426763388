import React, { FC } from "react"

import Header from "./Header"
import Footer from "./Footer"

import { LayoutProps } from "./types"

import { GlobalStyle } from "./styles"

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <GlobalStyle />
      {children}
      <Footer />
    </>
  )
}

export default Layout
