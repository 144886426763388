import React, { FC, useState } from 'react';

import NavbarLinks from './NavbarLinks';
import LogoWhite from '../Logo';

import { Wrapper, NavBox, Toggle, Hamburger } from './styles';

const Navbar: FC<{}> = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Wrapper>
      <LogoWhite />
      <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <NavBox>
          <NavbarLinks />
        </NavBox>
      ) : (
        <NavBox open>
          <NavbarLinks />
        </NavBox>
      )}
    </Wrapper>
  );
};
export default Navbar;
