import React, { FC } from 'react';

import { ContentBanner } from '../../ContentBanner';
import { Copyright } from './styles';

const Footer: FC<{}> = () => (
  <ContentBanner>
    <Copyright>Copyright 2020 Nimble Viz</Copyright>
  </ContentBanner>
);
export default Footer;
