import React, { FC } from "react"

import Navbar from "./Navbar"

import { Wrapper } from "./styles"

const Header: FC<{}> = () => (
  <Wrapper>
    <Navbar />
  </Wrapper>
)
export default Header
