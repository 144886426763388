import React, { FC } from 'react';
import { Item, ExternalLink, CTAButton, InternalLink } from './styles';

const NavbarLinks: FC<{}> = () => (
  <>
    <Item>
      <ExternalLink href="https://www.behance.net/nimbleviz" target="_blank">
        Behance
      </ExternalLink>
    </Item>
    <Item>
      <ExternalLink href="https://www.instagram.com/nimbleviz/" target="_blank">
        Instagram
      </ExternalLink>
    </Item>
    <Item>
      <ExternalLink
        href="https://www.youtube.com/playlist?list=PLA4k1FKKREyYEPzPTAaLrf5DYOMOb5WJT"
        target="_blank"
      >
        Youtube
      </ExternalLink>
    </Item>
    <CTAButton>
      <Item>
        <InternalLink to="/#contact">Get a quote</InternalLink>
      </Item>
    </CTAButton>
  </>
);
export default NavbarLinks;
